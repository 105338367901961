@import './variables.scss';

.App-container {
    font-family: $text-font;
    color: $text-color;
    font-size: $p-font-size;

    h1 {
        font-size: $h1-font-size;
        font-family: $h1-font-family;
    }

    h2 {
        font-size: $h2-font-size;
        font-family: $h2-font-family;
        color: $primary-color;
    }

    h3 {
        font-size: $h3-font-size;
        font-family: $h3-font-family;
    }

    .logo {
        width: 100%;
        height: auto;
    }

    .card-container {
        box-shadow: $down-shadow;
        border-radius: 8px;
        width: 100%;
        margin-bottom: 24px;
        
        .card-content {
            padding: 16px;

            .card-address {
                text-decoration: underline;
                font-size: 12px;
                font-weight: bold;
            }
        }
    }

    .chip-content {
        background-color: $chip-color;
        color: $text-color;
        text-align: center;
        border-radius: 16px;
        box-shadow: $down-shadow;
    }

    .btn-primary {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #FFFFFF;
        text-transform: uppercase;
        font-weight: bold;

        &:hover {
            background-color: $primary-color;
            border-color: $primary-color;
            color: #FFFFFF;
            text-transform: uppercase;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .App-header {
        display: none;

        .NavBar {
            font-family: $title-font;

            ul {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                .logo {
                    width: 50px;
                }
            }
        }
    }

    .scroll-to-top {
        position: fixed;
        bottom: 70px;
        right: 20px;
        padding: 10px 20px;
        cursor: pointer;
        transition: opacity 0.3s ease;
        border: none;
        background-color: white;
        border-radius: 50px;
        box-shadow: $down-shadow;

        .Logo-mobile {
            width: 50px;
        }
    }

    .Welcome-container {
        height: 100vh;
        display: flex;
        align-items: center;

        .Welcome-content {
            .Welcome-title {
                text-align: center;
                padding-top: 24px;

                h1 {
                    font-weight: lighter;
                    text-transform: uppercase;
                }
            }

            .Welcome-logo {
                display: flex;
                justify-content: center;
                
                .logo {
                    width: 70%;
                }
            }
        }
    }

    .About-container {
        height: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 100px;

        .About-content {
            .About-description {
                h2 {
                    margin-bottom: 40px;
                }
            }

            .About-photo {
                display: flex;
                justify-content: center;

                .Personal-photo {
                    width: 250px;
                }
            }
        }
    }

    .Study-container {
        height: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 100px;

        .Study-content {
            .Study-logo {
                display: none;
            }
        }

        .Study-description {
            h2 {
                margin-bottom: 40px;
            }
        }
    }

    .Skill-container {
        height: 100%;
        margin-bottom: 100px;

        .Skill-content {
            .Skill-description {
                h2 {
                    margin-bottom: 40px;
                }

                .Skill-web-description {
                    h3 {
                        text-align: center;
                    }
                }

                .Skill-graphic-description {
                    h3 {
                        text-align: center;
                    }
                }
            }

            .Skill-logo {
                display: none;
            }
        }
    }

    .Experience-container {
        height: 100vh;

        .Experience-content {

            .Experience-logo {
                display: none;
            }

            .Experience-description {
                h2 {
                    margin-bottom: 40px;
                }
            }
        }
    }

    .Contact-container {
        height: 100vh;
        margin-bottom: 50px;

        .Contact-content {

            .Contact-logo {
                display: flex;
                justify-content: center;
                
                .logo {
                    width: 70%;
                    margin-bottom: 40px;
                }
            }

            .Contact-mail {
                padding-top: 30px;

                a {
                    color: $text-color;
                    text-decoration: none;
                    font-weight: bold;
                    padding-left: 5px;
                }
            }
        }

        
    }

    .App-bottom {
        display: block;
        position: fixed;
        bottom: 0;
        background-color: #FFFFFF;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: $up-shadow;
        width: 100%;
        font-family: $title-font;

        .bottom-navigation {
            ul {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px 0 20px;

                li {
                    a {
                        color: $text-color;
                        text-decoration: none;
                        font-weight: bold;
                        
                        &.active {
                            color: $primary-color;
                            text-decoration: underline;
                        }
                    }
                }

            }
        }
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
/* Stili per dispositivi con larghezza tra 601px e 1024px */
}

@media (min-width: 1025px) {
    .App-container {

        .App-header {
            display: block;
            position: fixed;
            width: 100%;
            background-color: #FFFFFF;
            box-shadow: $down-shadow;
            padding-top: 10px;

            .NavBar {
                ul {
                    padding: 0 20px;

                    li {
                        a {
                            color: $text-color;
                            text-decoration: none;
                            font-weight: bold;
                            
                            &.active {
                                color: $primary-color;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .scroll-to-top {
            display: none;
        }

        .Welcome-container {

            .Welcome-content {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                .Welcome-title {
                    text-align: left;
                }
            }
        }

        .About-container {
            padding-top: 100px;

            .About-content {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                .About-photo {
                    .Personal-photo {
                        width: 500px;
                    }
                }
            }
        }

        .Study-container {
            padding-top: 100px;

            .Study-content {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                .Study-logo {
                    display: block;
                }
            }
        }

        .Skill-container {
            padding-top: 100px;

            .Skill-content {
                display: flex;
                align-items: center;

                .Skill-logo {
                    display: block;
                }
            }
        }

        .Experience-container {
            padding-top: 100px;

            .Experience-content {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;

                .Experience-logo {
                    display: block;
                }
            }
        }

        .Contact-container {
            padding-top: 100px;
    
            .Contact-content {
                display: flex;
                justify-content: center;
                align-items: center;
    
                .Contact-logo {
                    display: flex;
                    justify-content: center;
                    
                    .logo {
                        width: 100%;
                        margin-bottom: 00px;
                    }
                }
            }
    
            
        }

        .App-bottom {
            display: none;
        }
    }
}