$primary-color: #2B5CA7;
$text-color: #001020;
$chip-color: #c3c3c3;

$title-font: Aleo;
$text-font: Montserrat;

$h1-font-family: $title-font;
$h2-font-family: $title-font;
$h3-font-family: $title-font;

$h1-font-size: 40px;
$h2-font-size: 34px;
$h3-font-size: 28px;
$p-font-size: 16px;

$down-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
$up-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);